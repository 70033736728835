<template>
    <v-container fluid>
        <v-img
                class="white--text align-top"
                height="200px"
                gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                :src="`/images/Archivo.jpg`"
        >
            <v-card-title justify-top>
                Archivo clínico
            </v-card-title>
        </v-img>
        <v-toolbar dense color="primary">
            <v-btn
                icon
                dark
                x-small
            >
                <v-icon @click="$router.push({path:`/archivo/`})">
                    mdi-home
                </v-icon>
            </v-btn>
            <v-btn
                v-for="(itm, id) in modulos"
                :key="id"
                text
                dark
                x-small
                @click="$router.push({path: itm.ruta})"
            >
                {{itm.label}}
            </v-btn>
        </v-toolbar>

        <transition
            name="fade"
            mode="out-in"
        >
            <router-view/>
        </transition>

    </v-container>
</template>

<script>
export default {
    data:()=>({
        modulos:[
            {label:'Pacientes', ruta:'/archivo/pacientes'},
            {label:'A egresar', ruta:'/archivo/egresos'},
        ],
    }),

}
</script>

<style>

</style>